import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';

import { StyleSheet, css } from 'aphrodite/no-important';
import { DeviceConsumer } from './DeviceContext';
import brandBanner from '../assets/brand-banner.png';

const styles = StyleSheet.create({
  deviceIdContainer: {
    padding: '15px 6px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  brandBannerContainer: {
    width: '200px',
    float: 'right',
    marginRight: '10px',
    marginTop: '6px',
  },
  brandBanner: {
    filter: 'brightness(0) invert(1)',
    width: '200px',
  },
});

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    // marginLeft: 0,
    // width: '100%',
    marginLeft: theme.spacing(1),
    width: '112px',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    width: '74px',
  },
}));

export default function SearchAppBar() {
  const classes = useStyles();

  return (
    <DeviceConsumer>
      {({ updateDeviceId, deviceId, isAuthenticated }) => (
        <div className={classes.root}>
          <AppBar position="fixed">
            <div className={css(styles.deviceIdContainer)}>
              { isAuthenticated && (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  #
                </div>
                <InputBase
                  placeholder="Player Id"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  defaultValue={deviceId}
                  onChange={(event) => {
                    // eslint-disable-next-line no-param-reassign
                    event.target.value = event.target.value.toUpperCase().substring(0, 6);
                    updateDeviceId(event.target.value);
                  }}
                />
              </div>
              )}

              <div className={css(styles.brandBannerContainer)}>
                <img src={brandBanner} className={css(styles.brandBanner)} alt="Beverly Karaoke" />
              </div>
            </div>
          </AppBar>
        </div>
      )}
    </DeviceConsumer>
  );
}
