/* eslint-disable react/no-unused-state */
import React, {
  createContext, useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { constants } from '@natelewis/karaoke-common';
// eslint-disable-next-line import/no-unresolved
import { log } from '@natelewis/karaoke-utils';
import { useHistory } from 'react-router-dom';
import Peer from 'peerjs';

import { v4 as uuidv4 } from 'uuid';
import { playerApi } from '../utils/api';
import { useAuth0 } from '../react-auth0-spa';
import useInterval from '../utils/useInterval';

const send = require('peer-file/send');

const queryDeviceId = new URLSearchParams(window.location.search).get('deviceId');
const formattedDeviceId = queryDeviceId && queryDeviceId.toUpperCase();

const responseCallBack = {};
const setResponseCallBack = ({ id, callback }) => {
  if (callback) {
    responseCallBack[id] = callback;
  }
};
const handleResponseCallback = (response) => {
  if (responseCallBack[response.id]) {
    responseCallBack[response.id](response.data);
  }
};
const removeResponseCallback = ({ id }) => {
  delete responseCallBack[id];
};

const DeviceContext = createContext({
  deviceId: formattedDeviceId || window.localStorage.getItem('deviceId') || '',
  updateDeviceId: () => {},
  searchResults: [],
});

const peer = new Peer({
  host: 'karaoke-peerjs.herokuapp.com',
  port: 443,
  secure: true,
  config: {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      {
        urls: 'turn:numb.viagenie.ca',
        username:
      'natedeanlewis@gmail.com',
        credential: 'stinggers4life',
      },
    ],
  },
  debug: 0,
});

const {
  actions,
  playerStates,
  INITIAL_INFO_STATE,
} = constants;

const UPDATE_PLAYER_STATE_INTERVAL_DELAY = 1000;
const UPLOADER_INTERVAL_DELAY = 500;

// Only checking against major and minor build number
const REQUIRED_PLAYER_VERSION = '0.268.0';

const PLAYER_RESPONSE_INCREMENT = 10;
const PLAYER_RESPONSE_TIMEOUT = 10000;

const MAX_CONNECTION_ATTEMPTS = 5;

const { REACT_APP_API_ENDPOINT } = process.env;
log.info(`api: ${REACT_APP_API_ENDPOINT}`);

export const DeviceProvider = ({ children }) => {
  const history = useHistory();
  const { isAuthenticated, user, getTokenSilently } = useAuth0();
  const [deviceId, setDeviceId] = useState(
    formattedDeviceId
    || window.localStorage.getItem('deviceId')
    || '',
  );
  const [isConnected, setIsConnected] = useState(false);
  const [isConnectionError, setIsConnectionError] = useState(false);
  const [playerOutOfDate, setPlayerOutOfDate] = useState(false);
  const [appPlayerStatus, setAppPlayerStatus] = useState(playerStates.IDLE);
  const [isPlaying, setIsPlaying] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchFailed, setSearchFailed] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [currentTime, setCurrentTime] = useState(-1);
  const [songQueue, setSongQueue] = useState([]);
  const [songHistory, setSongHistory] = useState([]);
  const [playerControlState, setPlayerControlState] = useState(playerStates.PAUSED);
  const [info, setInfo] = useState(INITIAL_INFO_STATE);
  const [localSearchTerms, setLocalSearchTerms] = useState('');

  // peer
  const [conn, setConn] = useState();
  const [connectionFailure, setConnectionFailure] = useState(false);
  const [deviceIdNotValid, setDeviceIdNotValid] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [processingQueue, setProcessingQueue] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [connectionAttempts, setConnectionAttempts] = useState(0);

  const reconnectTimerRef = useRef();

  // these are used inside the setTimeout reconnection timer
  const connectionAttemptRef = useRef();
  useEffect(() => {
    connectionAttemptRef.current = connectionAttempts;
  }, [connectionAttempts]);

  const deviceIdNotValidRef = useRef();
  useEffect(() => {
    deviceIdNotValidRef.current = deviceIdNotValid;
  }, [deviceIdNotValid]);

  const callApiForPeerId = useCallback(async () => {
    const token = await getTokenSilently();
    const responseData = await playerApi({
      token,
      action: actions.PLAYER_PEER_ID,
      deviceId,
    });

    log.info('api:', responseData, peer);
    const peerId = responseData.playerPeerId;
    log.info('peer: (id)', peerId);

    if (!peerId) {
      setConnectionFailure(true);
      setDeviceIdNotValid(true);
      return;
    }

    setConn(peer.connect(peerId));
  }, [deviceId, getTokenSilently]);

  const callApiForPeerIdRef = useRef();
  useEffect(() => {
    callApiForPeerIdRef.current = callApiForPeerId;
  }, [callApiForPeerId]);


  const attemptReconnect = () => {
    reconnectTimerRef.current = setTimeout(() => {
      if (deviceIdNotValidRef.current) return;
      if (connectionAttemptRef.current > MAX_CONNECTION_ATTEMPTS) {
        return;
      }

      setConnectionAttempts((p) => {
        log.info('conn: (reconnect)', p + 1);
        return p + 1;
      });

      if (isConnected) {
        // this should never happen because the timer gets cleared
        // from the conn.on()
        log.info('conn: (is connected) resetting connection error and attempts');
        setConnectionAttempts(0);
        setIsConnectionError(false);
        return;
      }

      callApiForPeerIdRef.current();
      attemptReconnect();
    }, 5000);
  };

  // useEffect(() => {
  //   window.addEventListener('focus', () => {
  //     if (deviceIdNotValidRef.current) {
  //       log.info('window: (focus change without valid device id)');
  //       attemptReconnect();
  //     }
  //   });
  //   return () => {
  //     window.removeEventListener('focus', () => {});
  //   };
  // }, [attemptReconnect]);

  const callApiForSongHistory = async () => {
    const token = await getTokenSilently();
    const responseData = await playerApi({
      token,
      action: actions.SONG_HISTORY,
      deviceId,
    });

    log.info('api:', responseData);
    setSongHistory(responseData.songHistory);
  };

  useEffect(() => {
    if (!peer || !isAuthenticated || deviceId.length !== 6) return;
    log.info('peer: creating new peer');
    peer.on('open', async () => {
      log.info('peer: open');
      setUploadQueue([]);
      setProcessingQueue([]);
    });
    peer.on('error', (err) => {
      log.error('peer: ', err);
    });
    peer.on('disconnected', () => {
      log.info('peer: disconnected, recreating peer');
      peer.reconnect();
    });
    // eslint-disable-next-line consistent-return
    return () => {
      peer.off('open');
      peer.off('error');
      peer.off('disconnected');
    };
  }, [deviceId, getTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (!conn) return;
    conn.on('open', () => {
      log.info('conn: (open)');
      setConnectionFailure(false);
      setDeviceIdNotValid(false);
      setIsConnected(true);
      clearTimeout(reconnectTimerRef.current);
    });
    conn.on('close', () => {
      log.info('conn: (closed)');
      setConnectionFailure(true);
      setIsConnected(false);
      setUploadQueue([]);
      setProcessingQueue([]);
    });

    // this happens sometime and not sure where  --- but it will hit retry logic over and over
    // {error: "login_required", error_description: "Login required",
    //  state: "SHM2QktIVkd3SzQ0UC0xNWtWanFBdEVGM2VuNnM0LUdaVi1HVmhSM0ZRSQ=="}
    conn.on('error', (err) => {
      log.info('conn: (error)', err);
      setConnectionFailure(true);
      setIsConnected(false);
    });
    conn.on('data', (message) => {
      const { data } = message;

      if (message.type === 'info') {
        log.info('conn: <- (info)', data);
        setInfo(data);
      }

      if (message.type === 'processingQueue') {
        log.info('conn: <- (processingQueue)', data);
        setProcessingQueue(data);
      }

      if (message.type === 'response') {
        handleResponseCallback(message);
      }
    });
    // eslint-disable-next-line consistent-return
    return () => {
      conn.off('open');
      conn.off('close');
      conn.off('error');
      conn.off('data');
    };
  }, [conn]);

  useEffect(() => {
    if (deviceId.length !== 6) {
      setIsConnected(false);
      setConnectionFailure(false);
      setDeviceIdNotValid(false);
      if (conn) {
        if (conn.open) {
          log.info('conn: (disconnecting) deviceId not qualified');
          conn.close();
        }
      }
    }
  }, [conn, deviceId]);

  // auto peer connection reconnect
  useEffect(() => {
    if (!isAuthenticated || !peer) return;
    if (deviceId.length !== 6) return;

    // reset search so we don't start searching on reconnect
    // causing what would look like a search failure
    setSongQueue([]);

    if (isConnected) {
      setConnectionAttempts(0);
      log.info('conn: (connected)');
      return;
    }


    if (connectionAttemptRef.current > MAX_CONNECTION_ATTEMPTS) {
      return;
    }

    callApiForPeerIdRef.current();
    attemptReconnect();

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(reconnectTimerRef.current);
    };
  }, [isConnected, deviceId, isAuthenticated, connectionFailure]);

  useEffect(() => {
    callApiForSongHistory();
  }, [user]);

  const sendUploaderFiles = () => {
    if (uploading || !uploadQueue.length) return;

    const { file } = uploadQueue[0];
    setUploading(true);
    send(conn, file)
      .on('progress', (bytesSent) => {
        const percent = Math.ceil((bytesSent / file.size) * 100);
        setUploadProgress((prevState) => ({ ...prevState, [file.name]: percent }));
      })
      .on('complete', () => {
        setUploadQueue((prevState) => (prevState.filter((u) => u.filename !== file.name)));
        setUploadProgress((prevState) => ({ ...prevState, [file.name]: undefined }));
        setUploading(false);
      });
  };

  useInterval(sendUploaderFiles, UPLOADER_INTERVAL_DELAY);

  const getCurrentTimeFromInfo = useCallback(() => {
    const getCurrentSong = () => {
      const { song, queue } = info;
      if (!song || !queue.length) {
        return {};
      }
      const songInQueue = info.queue[0].song;
      return {
        ...song,
        songId: songInQueue.id,
        name: songInQueue.song,
        singer: songInQueue.name,
        artist: songInQueue.artist,
        songLength: songInQueue.songLength,
        thumbnail: songInQueue.meta && songInQueue.meta.album && songInQueue.meta.album.thumbnail,
      };
    };

    const song = getCurrentSong();
    return song.currentTime || -1;
  }, [info]);

  const getCurrentTimeFromInfoRef = useRef();
  useEffect(() => {
    getCurrentTimeFromInfoRef.current = getCurrentTimeFromInfo;
  }, [getCurrentTimeFromInfo]);

  const updateDeviceId = (did) => {
    setDeviceId(did);
    window.localStorage.setItem('deviceId', did);
  };

  const sendToPlayer = async ({ type, data }) => {
    const token = await getTokenSilently();
    log.info(`conn: -> (${type}) `, data);
    const id = uuidv4();
    await conn.send({
      data,
      id,
      type,
      token,
    });
    let response;
    setResponseCallBack({ id, callback: (res) => { response = res; } });

    for (let count = 0;
      count < PLAYER_RESPONSE_TIMEOUT;
      count += PLAYER_RESPONSE_INCREMENT
    ) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(resolve, PLAYER_RESPONSE_INCREMENT);
      });

      if (response) {
        log.info(`conn: <- (${type}) ${count}ms`, response);
        removeResponseCallback(id);
        return response;
      }
    }
    log.info(`conn: <- ${PLAYER_RESPONSE_TIMEOUT}ms (${type}) timeout`);
    removeResponseCallback(id);
    return null;
  };

  const searchPlayerSongs = async (terms) => {
    setIsSearching(true);
    setSearchFailed(false);
    const searchResultsSync = await sendToPlayer(
      {
        data: { terms },
        type: actions.SONG_SEARCH,
      },
    );
    if (searchResultsSync) {
      setSearchResults(searchResultsSync);
      setIsSearching(false);
      return;
    }
    setIsSearching(false);
    setSearchFailed(true);
  };

  useEffect(() => {
    if (!info || !info.timestamp) return;

    if (!info.version) {
      log.info('error: info state missing version');
      return;
    }
    setIsConnected(true);
  }, [info]);

  useEffect(() => {
    if (!info || !info.timestamp) return;

    if (!info.version) {
      log.info('error: info state missing version');
      return;
    }

    const reqParts = REQUIRED_PLAYER_VERSION.split('.');
    const apiParts = info.version.split('.');
    setPlayerOutOfDate(reqParts[0] > apiParts[0]
      || (reqParts[0] === apiParts[0] && reqParts[1] > apiParts[1]));

    setSongQueue(info.queue || []);
    setAppPlayerStatus(info.song.playerStatus);

    // smooth out current time skipping,  only set it if it is more than a second off
    setCurrentTime(
      (prevState) => (
        info.song.currentTime > prevState + 2 || info.song.currentTime < prevState - 2
          ? info.song.currentTime
          : prevState),
    );
  }, [info]);

  useEffect(() => {
    log.info(`playerStatus: (${appPlayerStatus})`);
    switch (appPlayerStatus) {
      // set to play, and spin till next play
      case playerStates.LOADING: {
        break;
      }
      case playerStates.PAUSED: {
        setPlayerControlState(playerStates.PAUSED);
        setCurrentTime(getCurrentTimeFromInfoRef.current());
        break;
      }
      case playerStates.IDLE: {
        setCurrentTime(0);
        setPlayerControlState(playerStates.PAUSED);
        break;
      }
      case playerStates.PLAYING: {
        setPlayerControlState(playerStates.PLAYING);
        setCurrentTime(getCurrentTimeFromInfoRef.current());
        break;
      }
      case playerStates.SONG_ENDED: {
        setIsPlaying(false);
        setPlayerControlState(playerStates.PAUSED);
        break;
      }
      default: {
        setCurrentTime(getCurrentTimeFromInfoRef.current());
      }
    }
  }, [appPlayerStatus]);

  const updatePlayerStates = () => {
    if (appPlayerStatus === playerStates.PLAYING) {
      const increment = (currentTime === -1) ? 2 : 1;
      setCurrentTime((prevState) => prevState + increment);
    }
  };

  useInterval(updatePlayerStates, UPDATE_PLAYER_STATE_INTERVAL_DELAY);

  const deleteFromLibrary = async ({ songId }) => sendToPlayer({
    data: { songId },
    type: actions.DELETE_FROM_LIBRARY,
  });

  const refetchSongData = async ({ song }) => {
    const songData = await sendToPlayer({
      data: { song },
      type: actions.REFETCH_SONG_DATA,
    });
    return songData;
  };

  const addToQueue = async ({ song, name }) => {
    await sendToPlayer({
      data: {
        song,
        name: name || user.name,
        picture: user.picture,
      },
      type: actions.QUEUE_ADD,
    });
    history.push('/queue');
    callApiForSongHistory();
  };

  const deleteFromHistory = async ({ id }) => {
    setSongHistory(songHistory.filter((s) => s.id !== id));
    sendToPlayer({
      data: {
        id,
      },
      type: actions.DELETE_FROM_HISTORY,
    });
  };

  const redirectToSearch = async ({ terms }) => {
    history.push('/search');
    setLocalSearchTerms(terms);
    searchPlayerSongs(terms);
  };

  const deleteFromQueue = async ({ id }) => sendToPlayer({
    data: { id },
    type: actions.QUEUE_DELETE,
  });

  const sortQueueSong = async ({ oldIndex, newIndex }) => sendToPlayer({
    // the queue is offset by one because the first one is in the player slot
    data: { oldIndex: oldIndex + 1, newIndex: newIndex + 1 },
    type: actions.QUEUE_MOVE,
  });

  const playerStart = async () => sendToPlayer({
    data: {},
    type: actions.PLAYER_START,
  });

  const playerPause = async () => sendToPlayer({
    data: {},
    type: actions.PLAYER_PAUSE,
  });

  const playerNextSong = async () => sendToPlayer({
    data: {},
    type: actions.PLAYER_NEXT_SONG,
  });

  const playerEjectSong = async () => sendToPlayer({
    data: {},
    type: actions.PLAYER_EJECT_SONG,
  });

  const playerSetTime = async ({ time }) => sendToPlayer({
    data: { time },
    type: actions.PLAYER_SET_TIME,
  });

  const playerRestartSong = async () => sendToPlayer({
    data: {},
    type: actions.PLAYER_RESTART_SONG,
  });

  return (
    <DeviceContext.Provider
      value={{
        // device
        conn,
        deviceId,
        updateDeviceId,
        info,
        isAuthenticated,
        connectionAttempts,

        // song actions
        deleteFromLibrary,
        refetchSongData,
        addToQueue,
        deleteFromQueue,
        sortQueueSong,

        // player HUD
        isConnected,
        connectionFailure,
        deviceIdNotValid,
        songQueue,
        isPlaying,
        currentTime,
        setCurrentTime,
        appPlayerStatus,
        playerOutOfDate,
        isConnectionError,
        playerControlState,
        setPlayerControlState,

        // player
        playerStart,
        playerPause,
        playerRestartSong,
        playerNextSong,
        playerSetTime,
        playerEjectSong,

        // search
        searchPlayerSongs,
        searchResults,
        searchFailed,
        isSearching,
        redirectToSearch,
        localSearchTerms,
        setLocalSearchTerms,

        songHistory,
        deleteFromHistory,

        uploadQueue,
        uploadProgress,
        processingQueue,
        setUploadQueue,


      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

DeviceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const DeviceConsumer = DeviceContext.Consumer;
export default DeviceContext;
