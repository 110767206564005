import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import RootRef from '@material-ui/core/RootRef';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import youTubeLogo from '../assets/yt_icon_rgb.png';

import AlbumArt from './AlbumArt';
import { timeFormat } from '../utils/timeConversion';
import { getYouTubeThumbnail, getAlbumThumbnail } from '../utils/thumbnail';

const styles = StyleSheet.create({
  searching: {
    marginTop: '70px',
    textAlign: 'center',
  },
  albumArtContainer: {
    position: 'relative',
    height: 0,
    top: '-4px',
  },
  albumArt: {
    position: 'relative',
    top: '-19px',
    left: '0',
  },
  youTubeLogo: {
    width: '19px',
  },
  youTubeThumbnail: {
    width: '50px',
    position: 'relative',
    top: '6px',
    height: '37px',
  },
  duration: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.34)',
    textAlign: 'center',
  },
  nativeType: {
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#4169e1',
    height: '17px',
    paddingTop: '6px',
  },
  youtubeThumbContainer: {
    backgroundColor: '#000',
    width: '50px',
    height: '50px',
    marginTop: '4px',
  },
  greyScaledText: {
    color: 'rgba(0, 0, 0, 0.34)',
  },
  vendor: {
    color: 'rgba(0, 0, 0, 0.34)',
    fontSize: '10px',
    display: 'block',
  },
  buttonSection: {
    position: 'relative',
    left: '42px',
    whiteSpace: 'nowrap',
  },
  dragHandle: {
    position: 'relative',
    left: '50px',
  },
  dragIcon: {
    color: 'rgba(0, 0, 0, 0.34)',
  },
  greyScaled: {
    filter: 'grayscale(100%)',
  },
});


const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;
  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;
    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

const arrayMove = (array, from, to) => {
  const arrayCopy = [...array];
  arrayMoveMutate(arrayCopy, from, to);
  return arrayCopy;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '360px',
    margin: 'auto',
    listStyleType: 'none',
  },
}));

const SongItem = ({
  item,
  songs,
  deviceId,
  setAddToQueueOpen,
  setDisplayModalItem,
  setSortableItems,
  isQueueList,
  isHistoryList,
  deleteFromQueue,
  provided,
  snapshot,
}) => {
  const openDisplayModal = () => {
    setDisplayModalItem(item);
    setAddToQueueOpen(true);
  };

  const isSearchList = !isQueueList && !isHistoryList;

  const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(false);

  const handleDeleteAlertOpen = () => {
    setDeleteAlertOpen(true);
  };

  const handleDeleteAlertClose = () => {
    setDeleteAlertOpen(false);
  };

  const albumThumbnail = getAlbumThumbnail(item);
  const youTubeThumbnail = getYouTubeThumbnail(item);


  const songTitle = (
    <span className={css(!item.isAvailable && styles.greyScaledText)}>
      {item.song}
    </span>
  );

  const primaryText = isQueueList
    ? item.name
    : songTitle;
  const secondaryText = isQueueList
    ? songTitle
    : (
      <span className={css(!item.isAvailable && styles.greyScaledText)}>
        {item.artist}
        <span className={css(styles.vendor)}>
          {item.vendor ? (
            <>
              [
              {item.vendor}
              ]
            </>
          ) : (<> &nbsp;</>)}
        </span>
      </span>
    );

  return (
    <ListItem
      ContainerProps={{ ref: provided.innerRef }}
      {...provided.draggableProps}

      style={getItemStyle(
        snapshot.isDragging,
        provided.draggableProps.style,
      )}
      ContainerComponent="li"
      disableGutters
      onClick={openDisplayModal}
      className={css(styles.listItem)}
    >
      <ListItemAvatar className={css(!item.isAvailable && styles.greyScaled)}>
        {youTubeThumbnail ? (
          <div className={css(styles.youtubeThumbContainer)}>
            <img
              alt="youTubeThumbnail"
              src={youTubeThumbnail}
              className={css(styles.youTubeThumbnail)}
            />
          </div>
        )
          : (
            <div className={css(styles.albumArtContainer)}>
              <AlbumArt albumArt={albumThumbnail} style={styles.albumArt} size={50} />
            </div>
          )}
      </ListItemAvatar>
      <ListItemText primary={primaryText} secondary={secondaryText} />

      {isQueueList && (
        <div className={css(styles.buttonSection)}>
          <Dialog
            open={deleteAlertOpen}
            onClose={handleDeleteAlertClose}
          >
            <DialogTitle id="alert-dialog-title">
              Remove
              {' '}
              {item.name}
              &apos;s song from queue?
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleDeleteAlertClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  deleteFromQueue({ deviceId, id: item.indexId });
                  setSortableItems(songs.filter((i) => i.id !== item.indexId));
                  handleDeleteAlertClose();
                }}
                color="primary"
                autoFocus
              >
                Remove Song
              </Button>
            </DialogActions>
          </Dialog>
          <IconButton
            aria-label="delete"
            onClick={() => {
              handleDeleteAlertOpen();
            }}
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            {...provided.dragHandleProps}
            aria-label="move"
            onClick={() => {
            }}
          >

            <DragHandleIcon />

          </IconButton>
        </div>
      )}

      { isSearchList && (
        <div>
          <div className={css(styles.duration)}>
            { timeFormat(item.songLength) }
          </div>
          <div className={css(styles.duration)}>
            { item.type === 'YOUTUBE'
              ? <img src={youTubeLogo} className={css(styles.youTubeLogo, !item.isAvailable && styles.greyScaled)} alt="Beverly Karaoke" />
              : (
                <div className={css(styles.nativeType)}>
                  {item.type && item.type.toUpperCase()}
                </div>
              )}
          </div>
        </div>
      )}
      <ListItemSecondaryAction />
    </ListItem>
  );
};


// Note on list structures
// history  [{id, isAvailable, song:{}}, ...}
// queue  [{id, song:{}}, ...}
// search list [song, ...]  -- isAvailable is a shows if the youtube is trusted or not
// this is because song.id is unique in search, but not in history or queue

const SongList = ({
  songs,
  setDisplayModalItem,
  addToQueueOpen,
  setAddToQueueOpen,
  addToQueue,
  isQueueList,
  isHistoryList,
  deleteFromQueue,
  redirectToSearch,
  sortQueueSong,
  deviceId,
}) => {
  const classes = useStyles();
  const [sortableItems, setSortableItems] = useState(songs);

  useEffect(() => {
    setSortableItems(songs.slice(1));
  }, [songs]);

  if (isHistoryList) {
    return (
      <List className={classes.root}>
        {songs.map((item) => (
          <SongItem
            key={item.id}
            item={{ ...item.song, indexId: item.id, isAvailable: item.isAvailable }}
            setDisplayModalItem={setDisplayModalItem}
            addToQueue={addToQueue}
            addToQueueOpen={addToQueueOpen}
            redirectToSearch={redirectToSearch}
            setAddToQueueOpen={setAddToQueueOpen}
            deleteFromQueue={deleteFromQueue}
            isQueueList={isQueueList}
            isHistoryList={isHistoryList}
            songs={songs}
            setSortableItems={setSortableItems}
            deviceId={deviceId}
          />
        ))}
      </List>
    );
  }

  return isQueueList ? (
    <div className={css(styles.songListContainer)}>
      <DragDropContext onDragEnd={(args) => {
        if (!args.destination) return;
        const oldIndex = args.source.index;
        const newIndex = args.destination.index;
        setSortableItems(arrayMove(sortableItems, oldIndex, newIndex));
        sortQueueSong({ oldIndex, newIndex });
      }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided && provided.innerRef}>
              <List className={classes.root}>
                {sortableItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <SongItem
                        provided={provided}
                        snapshot={snapshot}
                        key={item.id}
                        item={{ ...item.song, indexId: item.id }}
                        setDisplayModalItem={() => {}}
                        redirectToSearch={redirectToSearch}
                        addToQueue={() => {}}
                        addToQueueOpen={() => {}}
                        setAddToQueueOpen={() => {}}
                        deleteFromQueue={deleteFromQueue}
                        isQueueList={isQueueList}
                        isHistoryList={isHistoryList}
                        songs={songs}
                        setSortableItems={setSortableItems}
                        deviceId={deviceId}
                      />

                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
    : (
      <div className={css(styles.songListContainer)}>
        <List className={classes.root}>
          {songs.map((item) => (
            <SongItem
              key={item.id}
              item={item}
              setDisplayModalItem={setDisplayModalItem}
              addToQueue={addToQueue}
              addToQueueOpen={addToQueueOpen}
              setAddToQueueOpen={setAddToQueueOpen}
              isQueueList={isQueueList}
              isHistoryList={isHistoryList}
              deleteFromQueue={deleteFromQueue}
              redirectToSearch={redirectToSearch}
              deviceId="" // static lists don't need deviceId
              songs={[]}
            />
          ))}
        </List>
      </div>
    );
};

SongList.propTypes = {
  setDisplayModalItem: PropTypes.func.isRequired,
  songs: PropTypes.array.isRequired, // eslint-disable-line
  setAddToQueueOpen: PropTypes.func.isRequired,
  addToQueueOpen: PropTypes.bool.isRequired,
  addToQueue: PropTypes.func.isRequired,
  isQueueList: PropTypes.bool,
  isHistoryList: PropTypes.bool,
  deleteFromQueue: PropTypes.func,
  sortQueueSong: PropTypes.func,
  redirectToSearch: PropTypes.func,
  deviceId: PropTypes.string,
};

SongList.defaultProps = {
  isQueueList: false,
  isHistoryList: false,
  deleteFromQueue: () => {},
  sortQueueSong: () => {},
  redirectToSearch: () => {},
  deviceId: '',
};

SongItem.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line
  setAddToQueueOpen: PropTypes.func.isRequired,
  setDisplayModalItem: PropTypes.func.isRequired,
  songs: PropTypes.array.isRequired, // eslint-disable-line
  deviceId: PropTypes.string.isRequired,
  setSortableItems: PropTypes.func,
  isQueueList: PropTypes.bool.isRequired,
  isHistoryList: PropTypes.bool.isRequired,
  deleteFromQueue: PropTypes.func.isRequired,
  redirectToSearch: PropTypes.func.isRequired,
  provided: PropTypes.object,
  snapshot: PropTypes.object,
};

SongItem.defaultProps = {
  setSortableItems: () => {},
  provided: { draggableProps: {} },
  snapshot: {},
};

export default SongList;
