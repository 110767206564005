import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

const styles = StyleSheet.create({
  alertText: {
    textAlign: 'center',
    fontSize: '20px',
    paddingTop: '100px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold',
  },
});

const EmptyPage = ({ children }) => (
  <div className={css(styles.alertText)}>
    {children}
  </div>
);

EmptyPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyPage;
