import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

const styles = StyleSheet.create({
  albumArtContainer: {
    float: 'left',
    position: 'relative',
    top: '-2px',
  },
  songAlbumArt: {
    height: '18px',
  },
});

const AlbumArt = ({
  albumArt,
  size,
  style,
}) => {
  const imageStyle = {
    height: `${size}px`,
    width: `${size}px`,
  };

  return (
    <>
      {!albumArt ? (
        <div
          style={{ ...imageStyle, border: '1px #eee solid' }}
          className={css(styles.songAlbumArt, style)}
        >
          {' '}
        </div>
      ) : (
        <img
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
            e.target.style = `width:${size}px;height:${size}px;border: 1px solid #aaa;background-color: #eee;`;
          }}
          alt="albumArt"
          src={albumArt}
          style={imageStyle}
          className={css(styles.songAlbumArt, style)}
        />
      )}
    </>
  );
};

AlbumArt.propTypes = {
  albumArt: PropTypes.string,
  size: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
};

AlbumArt.defaultProps = {
  albumArt: '',
  size: 18,
};

export default AlbumArt;
