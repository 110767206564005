import React from 'react';
import { Button, Header } from './Form';
import { useAuth0 } from '../react-auth0-spa';

const queryDeviceId = new URLSearchParams(window.location.search).get('deviceId');

if (queryDeviceId) {
  window.localStorage.setItem('deviceId', queryDeviceId.toUpperCase());
}

const SplashLogin = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return !isAuthenticated && (
    <>
      <Header>{' '}</Header>
      <p>Welcome to Beverly Karaoke Beta!</p>
      <Button onClick={() => loginWithRedirect({})}>
        Login
      </Button>
    </>
  );
};

export default SplashLogin;
