import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import isMobile from 'is-mobile';
import SongName from './SongName';
import AlbumArt from './AlbumArt';
import Loading from './Loading';
import {
  Modal, Button, TextInput,
} from './Form';

const styles = StyleSheet.create({
  albumCover: {
    border: '1px solid #ddd',
    marginRight: '15px',
  },
  addToQueueContainer: {
    display: 'flex',
    marginBottom: '20px',
    height: '',
  },
  addToQueueButton: {
    float: 'right',
  },
  addToQueueFields: {
    flexGrow: '1',
  },
  songDataJSON: {
    overflowY: 'scroll',
    height: '200px',
    fontFamily: 'monospace',
    fontSize: '10px',
    backgroundColor: '#eee',
  },
  youTubeThumbnail: {
    marginRight: '15px',
  },
  youTubeContainer: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: '56.25%',
  },
  youTubeVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  songDisplayWrapper: {
    height: '556px',
    overflowY: 'scroll',
  },
  overFlowText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  exactMatchText: {
    display: 'block',
  },
});

const SongDisplayModal = ({
  item,
  deviceId,
  addToQueue,
  addToQueueOpen,
  setAddToQueueOpen,
  deleteFromLibrary,
  deleteFromHistory,
  refetchSongData,
  redirectToSearch,
  isHistoryList,
}) => {
  const [singerDisplayName, setSingerDisplayName] = useState(window.localStorage.getItem('singerDisplayName') || '');
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [songData, setSongData] = useState(item);

  useEffect(() => {
    setSongData(item);
  }, [item]);

  const handleRefetchSongData = async () => {
    const song = await refetchSongData({ song: songData });
    if (song) {
      setSongData(song);
    }
  };

  const handleDeleteAlertOpen = () => {
    setDeleteAlertOpen(true);
  };

  const handleDeleteAlertClose = () => {
    setDeleteAlertOpen(false);
  };

  const handleSingerDisplayNameChange = (e) => {
    const name = e.target.value;
    setSingerDisplayName(name);
    window.localStorage.setItem('singerDisplayName', name);
  };

  const albumArt = songData.meta
    && songData.meta.album
    && songData.meta.album.thumbnail;
  const youTubeThumbnail = songData.meta
    && songData.meta.thumbnails
    && songData.meta.thumbnails.default
    && songData.meta.thumbnails.default.url;

  const isLoading = !songData.meta;
  return (
    <Modal
      isOpen={addToQueueOpen}
      setIsOpen={setAddToQueueOpen}
      title={(
        <div className={css(styles.overFlowText)}>
          <SongName noVendor songData={item}>{' '}</SongName>
        </div>
      )}
    >


      <Dialog
        open={deleteAlertOpen}
        onClose={handleDeleteAlertClose}
      >
        <DialogTitle>
          Delete song from library?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>
              &apos;
              <SongName songData={item} />
              &apos;
            </b>
            {' '}
            will be removed and no longer playable.
            This action is not reversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAlertClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteFromLibrary({ songId: songData.id });
              handleDeleteAlertClose();
              setAddToQueueOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className={css(styles.songDisplayWrapper)}>
        <div className={css(styles.addToQueueContainer)}>
          <div className={css(styles.addToQueueAlbum)}>
            {youTubeThumbnail ? (
              <img
                alt="youTubeThumbnail"
                src={youTubeThumbnail}
                style={{ width: `${105}px` }}
                className={css(styles.youTubeThumbnail)}
              />
            )
              : (
                <AlbumArt
                  albumArt={albumArt}
                  style={styles.albumCover}
                  size={105}
                />
              )}
          </div>
          {!songData.isAvailable && songData.type !== 'YOUTUBE' ? (
            <div className={css(styles.exactMatchText)}>
              <div>
                The exact version of this song is not available on this player.
                <br />
                <br />
              </div>
              <div>
                { isHistoryList && (
                  <Button
                    noBottomPadding
                    onClick={() => {
                      deleteFromHistory({ id: item.indexId });
                      setAddToQueueOpen(false);
                    }}
                  >
                    Remove
                  </Button>
                )}
                <Button
                  noBottomPadding
                  onClick={() => {
                    redirectToSearch({ terms: songData.song });
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
          ) : (
            <div className={css(styles.addToQueueFields)}>
              <TextInput
                id="singer-name"
                label="Singer Name"
                value={singerDisplayName}
                onChange={handleSingerDisplayNameChange}
              />
              { isHistoryList && (
                <Button
                  noBottomPadding
                  onClick={() => {
                    deleteFromHistory({ id: item.indexId });
                    setAddToQueueOpen(false);
                  }}
                >
                  Remove
                </Button>
              )}
              {!isLoading
                ? (
                  <Button
                    style={styles.addToQueueButton}
                    noBottomPadding
                    onClick={() => {
                      addToQueue({ song: songData, deviceId, name: singerDisplayName });
                      setAddToQueueOpen(false);
                    }}
                  >
                    Add to Queue
                  </Button>
                )
                : (
                  <Button
                    style={styles.addToQueueButton}
                    noBottomPadding
                    onClick={() => { }}
                  >
                    <Loading size="small" />
                  </Button>
                )}
            </div>
          )}
        </div>
        {!youTubeThumbnail && (
          <>
            <br />
            <b>No Video Preview Available</b>
          </>
        )}
        {youTubeThumbnail && (
          <>
            <br />
            <b>Preview:</b>
            <i> (If video does not play, click link in video)</i>
            <div className={css(styles.youTubeContainer)}>
              <iframe
                className={css(styles.youTubeVideo)}
                src={`https://www.youtube.com/embed/${songData.id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="youtube embed"
              />
            </div>
          </>
        )}
        { !isMobile() && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <h6>Administration</h6>
            </AccordionSummary>
            <pre className={css(styles.songDataJSON)}>{JSON.stringify(item, null, 2)}</pre>
            <AccordionActions>
              <Button
                size="small"
                onClick={handleDeleteAlertOpen}
              >
                Delete From Library
              </Button>

              <Button
                size="small"
                onClick={handleRefetchSongData}
              >
                Refetch Song Data
              </Button>
            </AccordionActions>
          </Accordion>
        )}
      </div>
    </Modal>
  );
};

SongDisplayModal.propTypes = {
  deviceId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired, // eslint-disable-line
  addToQueue: PropTypes.func.isRequired,
  addToQueueOpen: PropTypes.bool.isRequired,
  isHistoryList: PropTypes.bool,
  setAddToQueueOpen: PropTypes.func.isRequired,
  deleteFromLibrary: PropTypes.func.isRequired,
  refetchSongData: PropTypes.func.isRequired,
  redirectToSearch: PropTypes.func.isRequired,
  deleteFromHistory: PropTypes.func.isRequired,
};

SongDisplayModal.defaultProps = {
  isHistoryList: false,
};

export default SongDisplayModal;
