import React from 'react';
import PropTypes from 'prop-types';
import MUIModal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MUIButton from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { StyleSheet, css } from 'aphrodite/no-important';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = StyleSheet.create({
  Title: {
    color: 'rgba(0, 0, 0, 0.7)',
  },
  TextInput: {
    ':focus': {
      outline: '0',
    },
    width: '100%',
  },
  Header: {
    paddingTop: '85px',
  },
  Button: {
    ':focus': {
      outline: '0 !important',
    },
    cursor: 'pointer',
  },
  cancelWrapper: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
});

export const Header = ({ children }) => (
  <div className={css(styles.Header)}>
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Title = ({ children }) => (
  <>
    <h4 className={css(styles.Title)}>
      {children}
    </h4>
    <Divider />
    <br />
  </>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SubTitle = ({ children }) => (
  <>
    <h6 className={css(styles.Title)}>
      {children}
    </h6>
    <Divider />
    <br />
  </>
);

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TextInput = ({
  label, id, value, onChange, onKeyUp,
}) => (
  <>
    <TextField
      fullWidth
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      className={css(styles.TextInput)}
    />
    <br />
    <br />
  </>
);

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
};

TextInput.defaultProps = {
  onChange: () => {},
  onKeyUp: () => {},
};

export const Button = ({
  onClick,
  children,
  variant,
  style,
  noBottomPadding,
}) => (
  <>
    <MUIButton
      className={css(styles.Button, style)}
      type="button"
      variant={variant}
      size="small"
      onClick={onClick}
    >
      {children}
    </MUIButton>
    {!noBottomPadding && (
      <>
        <br />
        <br />
      </>
    )}
  </>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  noBottomPadding: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Button.defaultProps = {
  onClick: () => {},
  variant: 'outlined',
  style: undefined,
  noBottomPadding: false,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '95%',
    margin: 'auto',
    marginTop: '30px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 'calc(100vh - 5Spx)',
    position: 'relative',
  },
}));

export const Modal = ({
  isOpen, onClose, title, children, setIsOpen,
}) => {
  const classes = useStyles();
  return (
    <MUIModal
      open={isOpen}
      onClose={onClose}
      onEscapeKeyDown={() => setIsOpen(false)}
    >
      <div className={classes.paper}>
        <div className={css(styles.cancelWrapper)}>
          <CancelIcon fontSize="large" onClick={() => setIsOpen(false)} />
        </div>
        <Title>{title}</Title>
        {children}
      </div>
    </MUIModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  onClose: () => {},
};
