import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { StyleSheet, css } from 'aphrodite/no-important';
// eslint-disable-next-line import/no-unresolved
import { constants } from '@natelewis/karaoke-common';
import Marquee from 'react-double-marquee';
import { use100vh } from 'react-div-100vh';
import { DeviceConsumer } from './DeviceContext';
import PlaybackControls from './PlaybackControls';
import Timeline from './Timeline';
import { useAuth0 } from '../react-auth0-spa';
import EmptyPage from './EmptyPage';
import SongList from './SongList';
import { getYouTubeThumbnail, getAlbumThumbnail } from '../utils/thumbnail';

const {
  playerStates,
} = constants;

const styles = StyleSheet.create({
  overFlowText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  songDetails: {
    paddingTop: '84px',
  },
  singerName: {
    fontWeight: 'bold',
    height: '25px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  songName: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontStyle: 'italic',
    height: '25px',
  },
  alertText: {
    textAlign: 'center',
    fontSize: '20px',
    paddingTop: '50px',
  },
  queueEmpty: {
    paddingTop: '150px',
  },
  albumThumbnail: {
    height: '105px',
    width: '105px',
  },
  songThumbnailContainer: {
    float: 'left',
    height: '107px',
    width: '107px',
    marginRight: '11px',
    border: '1px solid #ddd',
  },
  emptyQueueWrapper: {
    top: '190px',
    width: '100%',
    marginRight: '20px',
    left: 0,
  },
  playerControlContainer: {
    maxWidth: '360px',
    margin: 'auto',
    paddingBottom: '16px',
    borderBottom: '3px solid #eee',
  },
  songListContainer: {
    overflowY: 'scroll',
    height: '100%',
    minHeight: '-webkit-fill-available',
  },
});

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  // eslint-disable-next-line no-undef
  context.font = font || getComputedStyle(document.body).font;
  return context.measureText(text).width;
}

const SongListWithCorrectHeight = ({ children }) => {
  const height = use100vh();
  return <div style={{ height: height - 277 }}>{children}</div>;
};

const Dashboard = ({
  deviceId,
  songQueue,
  isPlaying,
  appPlayerStatus,
  currentTime,
  setCurrentTime,
  setPlayerControlState,
  playerControlState,
  deleteFromQueue,
  sortQueueSong,
  playerStart,
  playerRestartSong,
  playerNextSong,
  playerPause,
  playerSetTime,
  playerEjectSong,
}) => {
  const { isAuthenticated } = useAuth0();
  const [isPlayLoading, setIsPlayLoading] = useState(false);
  const [songLength, setSongLength] = useState(0);
  const [songName, setSongName] = useState('');
  const [songArtist, setSongArtist] = useState('');
  const [songThumbnail, setSongThumbnail] = useState('');

  const [singer, setSinger] = useState('');
  const debouncedSetTimeAPI = useDebouncedCallback(
    async (value) => {
      await playerSetTime({ time: value });
    },
    200, { maxWait: 1000 },
  );

  useEffect(() => {
    setIsPlayLoading(false);
  }, [appPlayerStatus, isPlaying]);

  useEffect(() => {
    const getCurrentSong = () => {
      if (!songQueue.length) {
        return {};
      }
      const songInQueue = songQueue[0].song;
      return {
        songId: songInQueue.id,
        name: songInQueue.song,
        singer: songQueue[0].name,
        artist: songInQueue.artist,
        songLength: songInQueue.songLength,
        thumbnail: getAlbumThumbnail(songInQueue),
        youTubeThumbnail: getYouTubeThumbnail(songInQueue),
      };
    };
    const getCurrentThumbnailFromInfo = () => {
      const song = getCurrentSong();
      return song.youTubeThumbnail || song.thumbnail || '';
    };
    const getSongLengthFromInfo = () => {
      const song = getCurrentSong();
      return song.songLength || 0;
    };
    const getSongNameFromInfo = () => {
      const song = getCurrentSong();
      return song.name || '';
    };
    const getSongArtistFromInfo = () => {
      const song = getCurrentSong();
      return song.artist || '';
    };
    const getSingerFromInfo = () => {
      const song = getCurrentSong();
      return song.singer || '';
    };
    setSongLength(getSongLengthFromInfo());
    setSongName(getSongNameFromInfo());
    setSongArtist(getSongArtistFromInfo());
    setSinger(getSingerFromInfo());
    setSongThumbnail(getCurrentThumbnailFromInfo());
  }, [songQueue]);

  const handleChange = (time) => {
    if (
      appPlayerStatus !== playerStates.SONG_ENDED
      && appPlayerStatus !== playerStates.LOADING
    ) {
      setCurrentTime(time);
      debouncedSetTimeAPI.callback(time);
    } else {
      setCurrentTime(-1);
    }
  };

  const songDisplayName = songArtist ? `${songArtist} - ${songName}` : songName;
  const songWidth = getTextWidth(songDisplayName);
  const showMarquee = songWidth > 245;

  return isAuthenticated && (
    <>
      <div className={css(styles.playerControlContainer)}>
        { !!songQueue.length && (
          <>
            <div className={css(styles.songDetails)}>
              <div className={css(styles.songThumbnailContainer)}>
                { songThumbnail && (
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
                      e.target.style = 'width:105px;height:105px;border: 1px solid #aaa;background-color: #eee;';
                    }}
                    src={songThumbnail}
                    alt="album"
                    className={css(styles.albumThumbnail)}
                  />
                )}
              </div>
              <div>
                <div className={css(styles.singerName, styles.overFlowText)}>
                  {singer}
                </div>
                <div className={css(styles.songName, styles.overFlowText)}>
                  {showMarquee
                    ? (
                      <Marquee direction="left" delay={5000} speed={0.02}>
                        {songDisplayName}
                      </Marquee>
                    )
                    : songDisplayName}
                </div>
              </div>
            </div>
            {' '}
            <Timeline
              isPlaying={isPlaying}
              currentTime={currentTime}
              songLength={songLength}
              handleChange={handleChange}
              appPlayerStatus={appPlayerStatus}
            />
            <PlaybackControls
              isPlaying={isPlaying}
              isPlayLoading={isPlayLoading}
              setIsPlayLoading={setIsPlayLoading}
              setPlayerControlState={setPlayerControlState}
              playerControlState={playerControlState}
              playerStart={playerStart}
              playerRestartSong={playerRestartSong}
              playerNextSong={playerNextSong}
              playerPause={playerPause}
              playerEjectSong={() => {
                playerEjectSong();
              }}
              showEject={songQueue.length > 1}
            />
          </>
        )}
      </div>
      {!!songQueue.length && (
        <SongListWithCorrectHeight>
          <div className={css(styles.songListContainer)}>
            <SongList
              isQueueList
              songs={songQueue}
              setDisplayModalItem={() => {}}
              addToQueue={() => {}}
              addToQueueOpen={false}
              setAddToQueueOpen={() => {}}
              deviceId={deviceId}
              deleteFromQueue={deleteFromQueue}
              sortQueueSong={sortQueueSong}
            />
          </div>
        </SongListWithCorrectHeight>
      )}
      {songQueue.length < 2 && (
        <div className={css(styles.emptyQueueWrapper)}>
          <EmptyPage>
            Song queue is empty
          </EmptyPage>
        </div>
      )}
    </>
  );
};

const DashboardContainer = () => (
  <DeviceConsumer>
    {({
      deviceId,
      isConnected,
      songQueue,
      sortQueueSong,
      isPlaying,
      currentTime,
      setCurrentTime,
      appPlayerStatus,
      setPlayerControlState,
      playerControlState,
      deleteFromQueue,
      playerStart,
      playerRestartSong,
      playerNextSong,
      playerPause,
      playerSetTime,
      playerEjectSong,
    }) => (
      <Dashboard
        deviceId={deviceId}
        isConnected={isConnected}
        songQueue={songQueue}
        isPlaying={isPlaying}
        currentTime={currentTime}
        setCurrentTime={setCurrentTime}
        appPlayerStatus={appPlayerStatus}
        setPlayerControlState={setPlayerControlState}
        playerControlState={playerControlState}
        deleteFromQueue={deleteFromQueue}
        sortQueueSong={sortQueueSong}
        playerStart={playerStart}
        playerRestartSong={playerRestartSong}
        playerNextSong={playerNextSong}
        playerPause={playerPause}
        playerSetTime={playerSetTime}
        playerEjectSong={playerEjectSong}
      />
    )}
  </DeviceConsumer>
);

Dashboard.propTypes = {
  deviceId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  songQueue: PropTypes.array.isRequired,
  currentTime: PropTypes.number.isRequired,
  setCurrentTime: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  appPlayerStatus: PropTypes.string.isRequired,
  setPlayerControlState: PropTypes.func.isRequired,
  playerControlState: PropTypes.string.isRequired,
  deleteFromQueue: PropTypes.func.isRequired,
  sortQueueSong: PropTypes.func.isRequired,
  playerStart: PropTypes.func.isRequired,
  playerRestartSong: PropTypes.func.isRequired,
  playerNextSong: PropTypes.func.isRequired,
  playerPause: PropTypes.func.isRequired,
  playerSetTime: PropTypes.func.isRequired,
  playerEjectSong: PropTypes.func.isRequired,
};

export default DashboardContainer;
