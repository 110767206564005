import { log } from '@natelewis/karaoke-utils';

const { REACT_APP_API_ENDPOINT } = process.env;

export const playerApi = async ({
  token,
  action,
  deviceId,
  params,
}) => {
  const paramsString = new URLSearchParams(params).toString();
  const url = new URL(`${REACT_APP_API_ENDPOINT}/player/${deviceId}/${action}?${paramsString}`);

  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { error };
  }
};

export const callApi = async ({
  getTokenSilently,
  action,
  deviceId,
  params,
}) => {
  const token = await getTokenSilently();
  let responseData = '';
  try {
    responseData = await playerApi({
      token,
      action,
      deviceId,
      params,
    });
    log.info(action, ':', responseData);
  } catch (error) {
    log.error(error);
  }

  return responseData;
};
