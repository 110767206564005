import React from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import MUIBottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import PublishIcon from '@material-ui/icons/Publish';
import HistoryIcon from '@material-ui/icons/History';
import { StyleSheet, css } from 'aphrodite/no-important';
import isMobile from 'is-mobile';


const styles = StyleSheet.create({
  BottomNavigation: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#eee !important',
  },
});

const BottomNavigation = () => (
  <MUIBottomNavigation
    showLabels
    className={css(styles.BottomNavigation)}
    value={useLocation().pathname}
  >
    <BottomNavigationAction value="/search" label="Search" icon={<SearchIcon />} component={Link} to="/search" />
    <BottomNavigationAction value="/queue" label="Queue" icon={<QueueMusicIcon />} component={Link} to="/queue" />
    <BottomNavigationAction value="/history" label="History" icon={<HistoryIcon />} component={Link} to="/history" />
    { !isMobile() && (
    <BottomNavigationAction value="/upload" label="Import" icon={<PublishIcon />} component={Link} to="/upload" />
    )}
    <BottomNavigationAction value="/profile" label="Profile" icon={<PersonIcon />} component={Link} to="/profile" />
  </MUIBottomNavigation>
);

export default withRouter(BottomNavigation);
