import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Title, Header, SubTitle } from './Form';
import { DeviceConsumer } from './DeviceContext';

import FileDropZone from './FileDropZone';

const styles = StyleSheet.create({
  loading: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  splitScreen: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    marginTop: '20px',
  },
});


const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
    listStyleType: 'none',
  },
}));


const FileShare = ({
  uploadQueue,
  processingQueue,
  uploadProgress,
  setUploadQueue,
}) => {
  const classes = useStyles();
  const ql = uploadQueue.length;
  const pl = processingQueue.length;
  return (
    <>
      <Header>
        <Title>
          Import Song Files
        </Title>
      </Header>
      <>
        <div>
          <FileDropZone setUploadQueue={setUploadQueue} />
          <div className={css(styles.splitScreen)}>
            <div className={css(styles.column)} style={{ marginRight: '10px' }}>
              <SubTitle>
                Uploading
                {' '}
                {uploadQueue.length ? `(${ql} song${ql > 1 ? 's' : ''})` : ''}
              </SubTitle>
              <List
                dense
                className={classes.root}
                aria-labelledby="nested-list-subheader"
              >
                { !!uploadQueue.length && uploadQueue.map((item, index) => (
                  <ListItem key={`upload-item-${item.filename}-${index}`}>
                    <ListItemText
                      primary={item.filename}
                      secondary={`${(item.size / 1000000).toFixed(2)}M ${uploadProgress[item.filename] ? ` - ${uploadProgress[item.filename]}%` : ''}`}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
            <div className={css(styles.column)} style={{ marginLeft: '10px' }}>
              <SubTitle>
                Processing
                {' '}
                {processingQueue.length ? `(${pl} file${pl > 1 ? 's' : ''})` : ''}
              </SubTitle>
              <List
                dense
                className={classes.root}
                aria-labelledby="nested-list-subheader"
              >
                { !!processingQueue.length && processingQueue.slice(0, 20).map((item, index) => (
                  <ListItem key={`process-item-${item.filename}-${index}`}>
                    <ListItemText
                      primary={item.replace(/\.uploadComplete$/, '').replace(/\.encoding\./, '.')}
                      secondary={item.match(/encoding\.(mp4|cdg)$/) ? 'In progress...' : ''}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

const FileShareContainer = () => (
  <DeviceConsumer>
    {({
      connectionFailure,
      isConnected,
      uploadQueue,
      processingQueue,
      uploadProgress,
      setUploadQueue,
    }) => (
      <FileShare
        isConnected={isConnected}
        connectionFailure={connectionFailure}
        uploadQueue={uploadQueue}
        processingQueue={processingQueue}
        uploadProgress={uploadProgress}
        setUploadQueue={setUploadQueue}
      />
    )}
  </DeviceConsumer>
);

FileShare.propTypes = {
  setUploadQueue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  uploadProgress: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  uploadQueue: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  processingQueue: PropTypes.array.isRequired,
};

export default FileShareContainer;
