import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { constants } from '@natelewis/karaoke-common';
import Slider from './ActiveSlider';
import { timeFormat } from '../utils/timeConversion';

const { playerStates } = constants;

const styles = StyleSheet.create({
  timelineWrapper: {
    paddingTop: '10px',
    height: '17px',
  },
  timelineContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    height: '4px',
  },
  timelineBarContainer: {
    paddingBottom: '5px',
    flexGrow: 1,
    position: 'relative',
    top: '-5px',
  },
  timelineTimeContainer: {
    paddingTop: '11px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  timelineText: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  timelineCurrentTime: {
    textAlign: 'right',
    paddingRight: '18px',
  },
  timelineSongLength: {
    textAlign: 'left',
    paddingLeft: '18px',
  },
});

const Timeline = ({
  songLength, currentTime, handleChange, appPlayerStatus,
}) => {
  const [scrubTime, setScrubTime] = useState(-1);

  // always reset scrub when incoming states change
  useEffect(() => {
    setScrubTime(-1);
  }, [currentTime, songLength]);

  const changeTime = async (newTime) => {
    handleChange(newTime);
  };

  return (
    <div className={css(styles.timelineWrapper)}>
      <div className={css(styles.timelineContainer)}>
        <div className={css(styles.timelineBarContainer)}>
          <Slider
            max={songLength}
            value={songLength === 0 ? -1 : currentTime}
            onChange={changeTime}
            disabled={appPlayerStatus === playerStates.IDLE}
          />
        </div>
      </div>
      <div className={css(styles.timelineTimeContainer)}>
        <div className={css(styles.timelineCurrentTime, styles.timelineText)}>
          {scrubTime !== -1 && timeFormat(scrubTime)}
          {scrubTime === -1 && songLength !== 0 && timeFormat(currentTime)}
        </div>
        <div className={css(styles.timelineSongLength, styles.timelineText)}>
          {songLength !== 0 ? timeFormat(songLength) : '00:00'}
        </div>
      </div>
    </div>
  );
};

Timeline.propTypes = {
  songLength: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  appPlayerStatus: PropTypes.string.isRequired,
};

export default Timeline;
