import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { constants } from '@natelewis/karaoke-common';
import {
  PlayArrow, Pause, SkipPrevious, Delete, SwapVert,
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { DeviceConsumer } from './DeviceContext';

const { actions, playerStates } = constants;

const styles = StyleSheet.create({
  playerControlsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  playerButton: {
    background: 'none',
    margin: '0 0px!important',
    border: 'none',
    ':focus': {
      outline: '0!important',
    },
  },

  // normalize some of the sizing of MUI buttons
  shrinkButton: {
    transform: 'scale(0.85)',
  },
  shrinkButtonMore: {
    transform: 'scale(0.65)',
  },
  enlargeButton: {
    transform: 'scale(1.15)',
  },
});

const PlaybackControls = ({
  isPlaying,
  setPlayerControlState,
  playerControlState,
  playerStart,
  playerRestartSong,
  playerNextSong,
  playerPause,
  playerEjectSong,
  showEject,
}) => {
  const [localIsPlaying, setLocalIsPlaying] = useState(playerControlState === playerStates.PLAYING);

  useEffect(() => {
    setLocalIsPlaying(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    setLocalIsPlaying(playerControlState === playerStates.PLAYING);
  }, [playerControlState]);


  const buttonHandler = ({ action, setCurrentTime }) => {
    if (action === actions.PLAYER_RESTART_SONG) {
      setCurrentTime(0);
      // setPlayerControlState(playerStates.PAUSED);
      playerRestartSong();
    }
    if (action === actions.PLAYER_PAUSE) {
      setLocalIsPlaying(false);
      setPlayerControlState(playerStates.PAUSED);
      playerPause();
    }
    if (action === actions.PLAYER_START) {
      setLocalIsPlaying(true);
      setPlayerControlState(playerStates.PLAYING);
      playerStart();
    }
    if (action === actions.PLAYER_NEXT_SONG) {
      setCurrentTime(0);
      setLocalIsPlaying(false);
      setPlayerControlState(playerStates.PAUSED);
      playerNextSong();
    }
    if (action === actions.PLAYER_EJECT_SONG) {
      setLocalIsPlaying(false);
      setPlayerControlState(playerStates.PAUSED);
      setCurrentTime(0);
      playerEjectSong();
    }
  };

  const isDisabled = playerControlState === playerStates.LOADING;

  if (isDisabled) {
    return (<div>loading...</div>);
  }

  return (
    <DeviceConsumer>
      {({ setCurrentTime }) => (
        <div className={css(styles.playerControlsContainer)}>
          <IconButton
            size="small"
            className={css(styles.playerButton)}
            onClick={() => buttonHandler({
              setCurrentTime,
              action: actions.PLAYER_RESTART_SONG,
            })}
          >
            <SkipPrevious fontSize="large" />
          </IconButton>
          { !localIsPlaying && (
            <IconButton
              size="small"
              className={css(styles.playerButton)}
              onClick={() => buttonHandler({
                action: actions.PLAYER_START,
              })}
            >
              <PlayArrow fontSize="large" className={css(styles.shrinkButton)} />

            </IconButton>
          ) }
          { localIsPlaying && (
          <IconButton
            size="small"
            className={css(styles.playerButton)}
            onClick={() => buttonHandler({
              action: actions.PLAYER_PAUSE,
            })}
          >
            <Pause fontSize="large" className={css(styles.shrinkButton)} />
          </IconButton>
          ) }
          <IconButton
            size="small"
            className={css(styles.playerButton)}
            onClick={() => buttonHandler({
              setCurrentTime,
              action: actions.PLAYER_NEXT_SONG,
            })}
          >
            <Delete fontSize="small" className={css(styles.enlargeButton)} />
          </IconButton>
          { showEject && (
          <IconButton
            size="small"
            className={css(styles.playerButton)}
            onClick={() => buttonHandler({
              setCurrentTime,
              action: actions.PLAYER_EJECT_SONG,
            })}
          >
            <SwapVert fontSize="large" className={css(styles.shrinkButtonMore)} />
          </IconButton>
          )}
        </div>
      )}
    </DeviceConsumer>
  );
};

PlaybackControls.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  setPlayerControlState: PropTypes.func.isRequired,
  playerControlState: PropTypes.string.isRequired,
  playerStart: PropTypes.func.isRequired,
  playerNextSong: PropTypes.func.isRequired,
  playerRestartSong: PropTypes.func.isRequired,
  playerPause: PropTypes.func.isRequired,
  playerEjectSong: PropTypes.func.isRequired,
  showEject: PropTypes.bool.isRequired,
};

export default PlaybackControls;
