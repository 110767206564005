import React from 'react';
import { PropTypes } from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite/no-important';
import { DeviceProvider, DeviceConsumer } from './components/DeviceContext';
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import TopBar from './components/TopBar';
import EmptyPage from './components/EmptyPage';
import Profile from './components/Profile';
import { useAuth0 } from './react-auth0-spa';
import history from './utils/history';
import BottomNavigation from './components/BottomNavigation';
import FileShare from './components/FileShare';
import SongSearch from './components/SongSearch';
import SongQueuePage from './components/SongQueuePage';
import SongHistory from './components/SongHistory';
import { Button } from './components/Form';

// styles
import './App.css';
import 'fontsource-roboto';

const MAX_CONNECTION_ATTEMPTS = 5;

const styles = StyleSheet.create({
  page: {
    margin: '0 15px',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  loading: {
    paddingTop: '200px',
    backgroundColor: 'white',
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
  },
});

const DisplayPage = ({
  deviceId,
  isConnected,
  isConnectionError,
  connectionFailure,
  isAuthenticated,
  playerOutOfDate,
  connectionAttempts,
  deviceIdNotValid,
}) => {
  if (connectionAttempts > MAX_CONNECTION_ATTEMPTS || deviceIdNotValid) {
    return (
      <EmptyPage>
        Not connected to player
        <br />
        <br />
        <Button onClick={() => { window.location.href = '/'; }}>Retry Connection</Button>
      </EmptyPage>
    );
  }
  if (isAuthenticated) {
    if (isConnectionError) {
      return (
        <EmptyPage>
          Connecting to Beverly Karaoke Service...
        </EmptyPage>
      );
    }

    if (playerOutOfDate) {
      return (
        <EmptyPage>
          To connect to player
          {' '}
          {deviceId}
          {' '}
          it must be upgraded to the newest version
        </EmptyPage>
      );
    }

    if (deviceId.length !== 6) {
      return (
        <EmptyPage>
          Add your Player ID to connect
        </EmptyPage>
      );
    }

    // if (deviceIdNotValid) {
    //   return (
    //     <EmptyPage>
    //       Player ID
    //       {' '}
    //       {deviceId}
    //       {' '}
    //       is not registered
    //     </EmptyPage>
    //   );
    // }

    if (connectionFailure) {
      return (
        <EmptyPage>
          Connecting to Beverly Karaoke Player
        </EmptyPage>
      );
    }

    if (deviceId.length === 6 && !isConnected) {
      return (
        <EmptyPage>
          Connecting to Beverly Karaoke Player
        </EmptyPage>
      );
    }

    if (isConnectionError) {
      return (
        <EmptyPage>
          Connecting to Beverly Karaoke Player
        </EmptyPage>
      );
    }
  }

  return (
    <div className={css(styles.page)}>
      <Switch>
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/search" component={SongSearch} />
        <PrivateRoute path="/upload" component={FileShare} />
        <PrivateRoute path="/queue" component={SongQueuePage} />
        <PrivateRoute path="/history" component={SongHistory} />
        <Route path="*" component={SongSearch} />
      </Switch>
    </div>
  );
};


const App = () => {
  const { loading, isAuthenticated } = useAuth0();

  if (loading) {
    return <div className={css(styles.loading)}><Loading /></div>;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <DeviceProvider>
          <TopBar />
          <DeviceConsumer>
            {({
              deviceId,
              isConnected,
              playerOutOfDate,
              isConnectionError,
              connectionFailure,
              connectionAttempts,
              deviceIdNotValid,
            }) => (
              <>
                <DisplayPage
                  isConnected={isConnected}
                  deviceId={deviceId}
                  isAuthenticated={isAuthenticated}
                  playerOutOfDate={playerOutOfDate}
                  isConnectionError={isConnectionError}
                  connectionFailure={connectionFailure}
                  connectionAttempts={connectionAttempts}
                  deviceIdNotValid={deviceIdNotValid}
                />
                { isAuthenticated && deviceId.length === 6 && <BottomNavigation />}
              </>
            )}
          </DeviceConsumer>
        </DeviceProvider>
      </div>
    </Router>
  );
};

DisplayPage.propTypes = {
  deviceId: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isConnectionError: PropTypes.bool.isRequired,
  connectionFailure: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  playerOutOfDate: PropTypes.bool.isRequired,
  connectionAttempts: PropTypes.number.isRequired,
  deviceIdNotValid: PropTypes.bool.isRequired,
};

export default App;
