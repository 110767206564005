import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { use100vh } from 'react-div-100vh';
import { DeviceConsumer } from './DeviceContext';
import SongDisplayModal from './SongDisplayModal';
import { useAuth0 } from '../react-auth0-spa';
import EmptyPage from './EmptyPage';
import SongList from './SongList';
import { Header, Title } from './Form';


const styles = StyleSheet.create({
  emptyQueueWrapper: {
    top: '190px',
    width: '100%',
    marginRight: '20px',
    left: 0,
  },
  unverifiedDivider: {
    maxWidth: '360px',
    margin: 'auto',
    color: 'rgba(0, 0, 0, 0.34)',
    borderBottom: 'solid 2px rgba(0, 0, 0, 0.34)',
    textAlign: 'center',
  },
  songListContainer: {
    overflowY: 'scroll',
    height: '100%',
    minHeight: '-webkit-fill-available',
  },
});

const SongListWithCorrectHeight = ({ children }) => {
  const height = use100vh();
  return <div style={{ height: height - 200 }}>{children}</div>;
};


const Dashboard = ({
  deviceId,
  deleteFromQueue,
  addToQueue,
  deleteFromLibrary,
  songHistory,
  redirectToSearch,
  refetchSongData,
  deleteFromHistory,
}) => {
  const { isAuthenticated } = useAuth0();
  const [addToQueueOpen, setAddToQueueOpen] = useState(false);
  const [displayModalItem, setDisplayModalItem] = useState({});

  return isAuthenticated && (
    <>
      <Header>
        <Title>
          Song History
        </Title>
      </Header>
      <SongDisplayModal
        isHistoryList
        setAddToQueueOpen={setAddToQueueOpen}
        songs={songHistory}
        addToQueueOpen={addToQueueOpen}
        item={displayModalItem}
        deviceId={deviceId}
        addToQueue={addToQueue}
        deleteFromHistory={deleteFromHistory}
        redirectToSearch={redirectToSearch}
        deleteFromLibrary={() => {
          deleteFromLibrary({ songId: displayModalItem.id });
          setAddToQueueOpen(false);
        }}
        refetchSongData={refetchSongData}
      />
      {!!songHistory.length && (
        <SongListWithCorrectHeight>
          <div className={css(styles.songListContainer)}>
            <SongList
              isHistoryList
              songs={songHistory.filter((song) => song.isAvailable)}
              deviceId={deviceId}
              deleteFromQueue={deleteFromQueue}
              setDisplayModalItem={setDisplayModalItem}
              addToQueue={addToQueue}
              addToQueueOpen={addToQueueOpen}
              setAddToQueueOpen={setAddToQueueOpen}
            />
            { songHistory.filter((f) => !f.isAvailable)[0] && (
            <div className={css(styles.unverifiedDivider)}>
              Songs not available on current player
            </div>
            )}
            <SongList
              isHistoryList
              songs={songHistory.filter((song) => !song.isAvailable)}
              deviceId={deviceId}
              deleteFromQueue={deleteFromQueue}
              setDisplayModalItem={setDisplayModalItem}
              addToQueue={addToQueue}
              addToQueueOpen={addToQueueOpen}
              setAddToQueueOpen={setAddToQueueOpen}
            />
          </div>
        </SongListWithCorrectHeight>
      )}
      {songHistory.length < 1 && (
        <div className={css(styles.emptyQueueWrapper)}>
          <EmptyPage>
            No songs in history
          </EmptyPage>
        </div>
      )}
    </>
  );
};

const DashboardContainer = () => (
  <DeviceConsumer>
    {({
      deviceId,
      deleteFromQueue,
      deleteFromLibrary,
      refetchSongData,
      addToQueue,
      songHistory,
      redirectToSearch,
      deleteFromHistory,
    }) => (
      <Dashboard
        deviceId={deviceId}
        deleteFromQueue={deleteFromQueue}
        deleteFromLibrary={deleteFromLibrary}
        addToQueue={addToQueue}
        refetchSongData={refetchSongData}
        songHistory={songHistory}
        redirectToSearch={redirectToSearch}
        deleteFromHistory={deleteFromHistory}
      />
    )}
  </DeviceConsumer>
);

Dashboard.propTypes = {
  deviceId: PropTypes.string.isRequired,
  refetchSongData: PropTypes.func.isRequired,
  deleteFromQueue: PropTypes.func.isRequired,
  addToQueue: PropTypes.func.isRequired,
  deleteFromLibrary: PropTypes.func.isRequired,
  redirectToSearch: PropTypes.func.isRequired,
  songHistory: PropTypes.array.isRequired,
  deleteFromHistory: PropTypes.func.isRequired,
};

export default DashboardContainer;
