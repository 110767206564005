import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Title, Button, Header } from './Form';
import { useAuth0 } from '../react-auth0-spa';
import Loading from './Loading';


const styles = StyleSheet.create({
  profileImage: {
    borderRadius: '50% 50%',
    width: '25px',
    height: 'auto',
    boxShadow: '0 0 4px 0 #b3bac7',
    marginRight: '15px',
  },
  logoutLink: {
    textDecoration: 'none !important',
  },
});

const Profile = () => {
  const {
    loading, user, logout,
  } = useAuth0();

  if (loading || !user) {
    return <Loading />;
  }

  const logoutWithRedirect = () => logout({
    returnTo: window.location.origin,
  });

  return (
    <>
      <Header>
        <Title>
          <img alt={user.name} className={css(styles.profileImage)} src={user.picture} />
          {user.name}
          {' '}
          (
          {user.email}
          )
        </Title>
      </Header>

      <pre>{JSON.stringify(user, null, 2)}</pre>


      <RouterNavLink
        to="#"
        id="qsLogoutBtn"
        onClick={() => logoutWithRedirect()}
        className={css(styles.logoutLink)}
      >
        <Button>
          Log out
        </Button>
      </RouterNavLink>


    </>
  );
};

export default Profile;
