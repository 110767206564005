import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

const ActiveSlider = ({
  handle,
  max,
  value,
  onChange,
  min,
  disabled,
}) => {
  const [val, setVal] = useState(value);
  const [isDragging, setIsDragging] = useState(false);

  return (
    <Slider
      handle={handle}
      min={min}
      max={max}
      step={1}
      value={isDragging ? val : value}
      onChange={setVal}
      disabled={disabled}
      onBeforeChange={() => setIsDragging(true)}
      onAfterChange={() => {
        setIsDragging(false);
        onChange(val);
      }}
    />
  );
};

ActiveSlider.propTypes = {
  handle: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ActiveSlider.defaultProps = {
  handle: undefined,
  min: 0,
  max: 100,
};

export default ActiveSlider;
