import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

const styles = StyleSheet.create({
  songVendor: {
    position: 'relative',
    fontSize: '8px',
    paddingLeft: '7px',
    top: '-3px',
    fontWeight: 800,
  },
});

const SongName = ({ songData, noVendor }) => (
  <>
    {songData.song}
    {' '}
    -
    {' '}
    {songData.artist}
    { !noVendor && songData.vendor && (
    <span className={css(styles.songVendor)}>
      [
      {songData.vendor}
      ]
    </span>
    )}
  </>
);

SongName.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  songData: PropTypes.object.isRequired,
  noVendor: PropTypes.bool,
};
SongName.defaultProps = {
  noVendor: false,
};

export default SongName;
