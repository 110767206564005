import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { Search } from '@material-ui/icons';
import { useDebouncedCallback } from 'use-debounce';
import { use100vh } from 'react-div-100vh';
import { useAuth0 } from '../react-auth0-spa';

import SplashLogin from './SplashLogin';
import SongDisplayModal from './SongDisplayModal';
import Loading from './Loading';
import { DeviceConsumer } from './DeviceContext';
import { Header } from './Form';
import SongList from './SongList';

const styles = StyleSheet.create({
  searching: {
    marginTop: '70px',
    textAlign: 'center',
  },
  searchFailed: {
    marginTop: '30px',
    textAlign: 'center',
  },
  searchInput: {
    ':focus': {
      outline: '0px',
    },
    width: '100%',
  },
  searchButton: {
    ':focus': {
      outline: '0px',
    },
    position: 'relative',
    top: '-28px',
    left: 'calc(100% - 28px)',
    backgroundColor: 'Transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
    color: '#767676',
  },
  searchButtonContainer: {
    position: 'relative',
    height: 0,
    left: '5px',
  },
  songListContainer: {
    overflowY: 'scroll',
    height: '100%',
    minHeight: '-webkit-fill-available',
  },
  searchInputContainer: {
    maxWidth: '360px',
    margin: 'auto',
  },
  unverifiedDivider: {
    maxWidth: '360px',
    margin: 'auto',
    color: 'rgba(0, 0, 0, 0.34)',
    borderBottom: 'solid 2px rgba(0, 0, 0, 0.34)',
    textAlign: 'center',
  },
});

const SongListWithCorrectHeight = ({ children }) => {
  const height = use100vh();
  return <div style={{ height: height - 200 }}>{children}</div>;
};

const SongSearch = ({
  deviceId,
  searchResults,
  isSearching,
  searchFailed,
  deleteFromLibrary,
  refetchSongData,
  addToQueue,
  searchPlayerSongs,
  setLocalSearchTerms,
  localSearchTerms,
}) => {
  const { isAuthenticated } = useAuth0();
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [addToQueueOpen, setAddToQueueOpen] = useState(false);
  const [displayModalItem, setDisplayModalItem] = useState({});
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    setSongs(searchResults.map((song) => ({ ...song, isAvailable: !!song.artist })));
    setIsDebouncing(false);
  }, [searchResults]);

  const debouncedInput = useDebouncedCallback((value) => {
    searchPlayerSongs(value);
    setIsDebouncing(false);
  },
  1000);

  const handleChange = ({ event }) => {
    setSongs([]);
    setLocalSearchTerms(event.target.value);
    debouncedInput.callback(event.target.value);
    if (event.target.value) {
      setIsDebouncing(true);
    } else {
      setIsDebouncing(false);
    }
  };

  const searchNotAvailable = searchFailed && localSearchTerms;
  const isNotSearching = !isSearching && !isDebouncing && localSearchTerms;

  return isAuthenticated && (
    <>
      <SongDisplayModal
        setAddToQueueOpen={setAddToQueueOpen}
        songs={songs}
        addToQueueOpen={addToQueueOpen}
        item={displayModalItem}
        deviceId={deviceId}
        addToQueue={addToQueue}
        deleteFromLibrary={() => {
          deleteFromLibrary({ songId: displayModalItem.id });
          setAddToQueueOpen(false);
        }}
        refetchSongData={refetchSongData}
        deleteFromHistory={() => {}}
        redirectToSearch={() => {}}
      />
      <Header><></></Header>
      <>
        {' '}
        <div className={css(styles.searchInputContainer)}>
          <TextField
            id="standard-basic"
            label="Search songs by song and artist names"
            className={css(styles.searchInput)}
            type="text"
            value={localSearchTerms}
            autoComplete="off"
            onChange={(event) => handleChange({ event })}
          />
          <div className={css(styles.searchButtonContainer)}>
            <IconButton
              size="small"
              className={css(styles.searchButton)}
            >
              <Search fontSize="small" />
            </IconButton>
          </div>
        </div>
      </>
      {isNotSearching && !searchNotAvailable && !searchResults.length && (
      <div className={css(styles.searchFailed)}>
        No results found for
        {' '}
        <b>
          &apos;
          {' '}
          {localSearchTerms}
          {' '}
          &apos;
        </b>
      </div>
      )}
      {(isSearching || isDebouncing) && (
        <div className={css(styles.searching)}>
          <Loading />
        </div>
      )}
      {searchNotAvailable && (
      <div className={css(styles.searchFailed)}>
        Search not available, try again later.
      </div>
      )}
      <SongListWithCorrectHeight>
        <div className={css(styles.songListContainer)}>
          <SongList
            songs={songs.filter((f) => f.isAvailable)}
            setDisplayModalItem={setDisplayModalItem}
            addToQueue={addToQueue}
            addToQueueOpen={addToQueueOpen}
            setAddToQueueOpen={setAddToQueueOpen}
          />
          { songs.filter((f) => !f.isAvailable)[0] && (
          <div className={css(styles.unverifiedDivider)}>
            Songs from unverified collections
          </div>
          )}
          <SongList
            songs={songs.filter((f) => !f.isAvailable)}
            setDisplayModalItem={setDisplayModalItem}
            addToQueue={addToQueue}
            addToQueueOpen={addToQueueOpen}
            setAddToQueueOpen={setAddToQueueOpen}
          />
        </div>
      </SongListWithCorrectHeight>
    </>
  );
};

SongSearch.propTypes = {
  deviceId: PropTypes.string.isRequired,
  searchResults: PropTypes.array.isRequired, // eslint-disable-line
  isSearching: PropTypes.bool.isRequired,
  searchFailed: PropTypes.bool.isRequired,
  deleteFromLibrary: PropTypes.func.isRequired,
  refetchSongData: PropTypes.func.isRequired,
  addToQueue: PropTypes.func.isRequired,
  searchPlayerSongs: PropTypes.func.isRequired,
  localSearchTerms: PropTypes.string.isRequired,
  setLocalSearchTerms: PropTypes.func.isRequired,
};


const SongSearchContainer = () => (
  <>
    <SplashLogin />
    <DeviceConsumer>
      {({
        conn,
        deviceId,
        searchResults,
        isSearching,
        songSearch,
        searchFailed,
        deleteFromLibrary,
        refetchSongData,
        addToQueue,
        searchPlayerSongs,
        setLocalSearchTerms,
        localSearchTerms,
        deleteFromHistory,
      }) => (
        <SongSearch
          deviceId={deviceId}
          searchResults={searchResults}
          searchFailed={searchFailed}
          isSearching={isSearching}
          songSearch={songSearch}
          setLocalSearchTerms={setLocalSearchTerms}
          localSearchTerms={localSearchTerms}
          conn={conn}
          deleteFromLibrary={deleteFromLibrary}
          deleteFromHistory={deleteFromHistory}
          refetchSongData={refetchSongData}
          addToQueue={addToQueue}
          searchPlayerSongs={searchPlayerSongs}
        />
      )}
    </DeviceConsumer>
  </>
);

export default SongSearchContainer;
