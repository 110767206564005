import React from 'react';
import loading from '../assets/loading.svg';

const Loading = ({ size }) => (size === 'small'
  ? (
    <img src={loading} alt="Loading" style={{ width: '23px' }} />
  )
  : (
    <img src={loading} alt="Loading" />
  ));

export default Loading;
