var playerStates = {
  LOADING: 'loading',
  PLAYING: 'playing',
  PAUSED: 'paused',
  SONG_ENDED: 'songEnded',
  LOADED: 'loaded',
  IDLE: 'idle'
};
var INITIAL_INFO_STATE = {
  song: {},
  queue: []
};
var actions = {
  USER_REGISTER: 'userRegister',
  PLAYER_REGISTER: 'playerRegister',
  PLAYER_START: 'playerStart',
  PLAYER_RESTART_SONG: 'playerResetSong',
  PLAYER_PAUSE: 'playerPause',
  PLAYER_EJECT_SONG: 'playerEjectSong',
  PLAYER_SET_TIME: 'playerSetTime',
  PLAYER_PITCH: 'playerPitch',
  PLAYER_LOAD: 'playerLoad',
  PLAYER_PEER_ID: 'playerPeerId',
  PLAYER_UPDATE_INFO: 'playerUpdateInfo',
  YOUTUBE_SEARCH: 'youTubeSearch',
  SONG_HISTORY: 'songHistory',
  DELETE_FROM_HISTORY: 'deleteFromHistory',
  // service end points also
  PLAYER_NEXT_SONG: 'playerNextSong',
  REFETCH_SONG_DATA: 'refetchSongData',
  WAIT_NEXT_UP: 'nextUp',
  WAIT_QUEUE_EMPTY: 'queueEmpty',
  SONG_SEARCH: 'songSearch',
  DELETE_FROM_LIBRARY: 'deleteFromLibrary',
  QUEUE_ADD: 'queueAdd',
  QUEUE_DELETE: 'queueDelete',
  QUEUE_MOVE: 'queueMove',
  QUEUE_NEXT_SONG: 'queueNextSong'
};
var icons = {
  UPLOAD: 'faFileUpload',
  SPINNER: 'faSpinner',
  PLAY: 'faPlay',
  PAUSE: 'faPause',
  NEXT_SONG: 'faForward',
  RESTART_SONG: 'faBackward',
  SEARCH: 'faSearch',
  DELETE: 'faTrash',
  ADD: 'faPlus'
};
var songTypes = {
  CDG: 'cdg',
  ZIP: 'zip'
};
var test = {
  DEVICE_ID: 'ABC123',
  USER_ID: 'testuser'
};
module.exports.INITIAL_INFO_STATE = INITIAL_INFO_STATE;
module.exports.playerStates = playerStates;
module.exports.test = test;
module.exports.icons = icons;
module.exports.actions = actions;
module.exports.songTypes = songTypes;